import {
  FILE_KEY_SEPARATOR,
  FileService,
  FileType,
  GV_FILE_DEFAULT_SVC_VENDOR,
  GV_FILE_SVC_ATS_HOST,
  GV_FILE_SVC_NGX_HOST,
  GV_FILE_SVC_TENCENT_COS_HOST,
  GV_FILE_SVC_TENCENT_COS_POLICY,
  GV_FILE_SVC_TENCENT_COS_Q_AK,
  GV_FILE_SVC_TENCENT_COS_Q_KEY_TIME,
  GV_FILE_SVC_TENCENT_COS_Q_SIGNATURE,
  UploadedFile
} from '@/module/common/file-service/types'
import LocalDbDao from '@/module/common/local-db-dao'
import { Indexed } from '@/d2admin/delegate'
import axios, { AxiosInstance } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import fileDownload from 'js-file-download'
import _ from 'lodash'

class AbstractFileService implements FileService {
  protected downloader = axios.create({
    method: 'GET'
  })

  getFileType(filename: string): FileType {
    const extName = filename.toLowerCase().split('.').pop()

    switch (extName) {
      case 'psd':
      case 'png':
      case 'bmp':
      case 'heic':
      case 'jpeg':
      case 'jpg': return FileType.IMAGE
      case 'ofd':
      case 'pdf': return FileType.PDF
      case 'ai': return FileType.DESIGN
      case 'docx':
      case 'doc': return FileType.WORD
      case 'xlsx':
      case 'xls': return FileType.EXCEL
      default: return FileType.OTHER
    }
  }

  getContentType(filename: string): string {
    const extName = filename.toLowerCase().split('.').pop()
    switch (extName) {
      case 'ai': return 'application/postscript'
      case 'aif': return 'audio/x-aiff'
      case 'aiff': return 'audio/x-aiff'
      case 'asc': return 'text/plain'
      case 'atom': return 'application/atom+xml'
      case 'au': return 'audio/basic'
      case 'avi': return 'video/x-msvideo'
      case 'bcpio': return 'application/x-bcpio'
      case 'bin': return 'application/octet-stream'
      case 'bmp': return 'image/bmp'
      case 'cdf': return 'application/x-netcdf'
      case 'cgm': return 'image/cgm'
      case 'class': return 'application/octet-stream'
      case 'cpio': return 'application/x-cpio'
      case 'cpt': return 'application/mac-compactpro'
      case 'csh': return 'application/x-csh'
      case 'css': return 'text/css'
      case 'dcr': return 'application/x-director'
      case 'dif': return 'video/x-dv'
      case 'dir': return 'application/x-director'
      case 'djv': return 'image/vnd.djvu'
      case 'djvu': return 'image/vnd.djvu'
      case 'dll': return 'application/octet-stream'
      case 'dmg': return 'application/octet-stream'
      case 'dms': return 'application/octet-stream'
      case 'doc': return 'application/msword'
      case 'dtd': return 'application/xml-dtd'
      case 'dv': return 'video/x-dv'
      case 'dvi': return 'application/x-dvi'
      case 'dxr': return 'application/x-director'
      case 'eps': return 'application/postscript'
      case 'etx': return 'text/x-setext'
      case 'exe': return 'application/octet-stream'
      case 'ez': return 'application/andrew-inset'
      case 'gif': return 'image/gif'
      case 'gram': return 'application/srgs'
      case 'grxml': return 'application/srgs+xml'
      case 'gtar': return 'application/x-gtar'
      case 'heic': return 'image/heic'
      case 'hdf': return 'application/x-hdf'
      case 'hqx': return 'application/mac-binhex40'
      case 'htm': return 'text/html'
      case 'html': return 'text/html'
      case 'ice': return 'x-conference/x-cooltalk'
      case 'ico': return 'image/x-icon'
      case 'ics': return 'text/calendar'
      case 'ief': return 'image/ief'
      case 'ifb': return 'text/calendar'
      case 'iges': return 'model/iges'
      case 'igs': return 'model/iges'
      case 'jnlp': return 'application/x-java-jnlp-file'
      case 'jp2': return 'image/jp2'
      case 'jpe': return 'image/jpeg'
      case 'jpeg': return 'image/jpeg'
      case 'jpg': return 'image/jpeg'
      case 'js': return 'application/x-javascript'
      case 'kar': return 'audio/midi'
      case 'latex': return 'application/x-latex'
      case 'lha': return 'application/octet-stream'
      case 'lzh': return 'application/octet-stream'
      case 'm3u': return 'audio/x-mpegurl'
      case 'm4a': return 'audio/mp4a-latm'
      case 'm4b': return 'audio/mp4a-latm'
      case 'm4p': return 'audio/mp4a-latm'
      case 'm4u': return 'video/vnd.mpegurl'
      case 'm4v': return 'video/x-m4v'
      case 'mac': return 'image/x-macpaint'
      case 'man': return 'application/x-troff-man'
      case 'mathml': return 'application/mathml+xml'
      case 'me': return 'application/x-troff-me'
      case 'mesh': return 'model/mesh'
      case 'mid': return 'audio/midi'
      case 'midi': return 'audio/midi'
      case 'mif': return 'application/vnd.mif'
      case 'mov': return 'video/quicktime'
      case 'movie': return 'video/x-sgi-movie'
      case 'mp2': return 'audio/mpeg'
      case 'mp3': return 'audio/mpeg'
      case 'mp4': return 'video/mp4'
      case 'mpe': return 'video/mpeg'
      case 'mpeg': return 'video/mpeg'
      case 'mpg': return 'video/mpeg'
      case 'mpga': return 'audio/mpeg'
      case 'ms': return 'application/x-troff-ms'
      case 'msh': return 'model/mesh'
      case 'mxu': return 'video/vnd.mpegurl'
      case 'nc': return 'application/x-netcdf'
      case 'oda': return 'application/oda'
      case 'ogg': return 'application/ogg'
      case 'pbm': return 'image/x-portable-bitmap'
      case 'pct': return 'image/pict'
      case 'pdb': return 'chemical/x-pdb'
      case 'pdf': return 'application/pdf'
      case 'pgm': return 'image/x-portable-graymap'
      case 'pgn': return 'application/x-chess-pgn'
      case 'pic': return 'image/pict'
      case 'pict': return 'image/pict'
      case 'png': return 'image/png'
      case 'pnm': return 'image/x-portable-anymap'
      case 'pnt': return 'image/x-macpaint'
      case 'pntg': return 'image/x-macpaint'
      case 'ppm': return 'image/x-portable-pixmap'
      case 'ppt': return 'application/vnd.ms-powerpoint'
      case 'ps': return 'application/postscript'
      case 'qt': return 'video/quicktime'
      case 'qti': return 'image/x-quicktime'
      case 'qtif': return 'image/x-quicktime'
      case 'ra': return 'audio/x-pn-realaudio'
      case 'ram': return 'audio/x-pn-realaudio'
      case 'ras': return 'image/x-cmu-raster'
      case 'rdf': return 'application/rdf+xml'
      case 'rgb': return 'image/x-rgb'
      case 'rm': return 'application/vnd.rn-realmedia'
      case 'roff': return 'application/x-troff'
      case 'rtf': return 'text/rtf'
      case 'rtx': return 'text/richtext'
      case 'sgm': return 'text/sgml'
      case 'sgml': return 'text/sgml'
      case 'sh': return 'application/x-sh'
      case 'shar': return 'application/x-shar'
      case 'silo': return 'model/mesh'
      case 'sit': return 'application/x-stuffit'
      case 'skd': return 'application/x-koan'
      case 'skm': return 'application/x-koan'
      case 'skp': return 'application/x-koan'
      case 'skt': return 'application/x-koan'
      case 'smi': return 'application/smil'
      case 'smil': return 'application/smil'
      case 'snd': return 'audio/basic'
      case 'so': return 'application/octet-stream'
      case 'spl': return 'application/x-futuresplash'
      case 'src': return 'application/x-wais-source'
      case 'sv4cpio': return 'application/x-sv4cpio'
      case 'sv4crc': return 'application/x-sv4crc'
      case 'svg': return 'image/svg+xml'
      case 'swf': return 'application/x-shockwave-flash'
      case 't': return 'application/x-troff'
      case 'tar': return 'application/x-tar'
      case 'tcl': return 'application/x-tcl'
      case 'tex': return 'application/x-tex'
      case 'texi': return 'application/x-texinfo'
      case 'texinfo': return 'application/x-texinfo'
      case 'tif': return 'image/tiff'
      case 'tiff': return 'image/tiff'
      case 'tr': return 'application/x-troff'
      case 'tsv': return 'text/tab-separated-values'
      case 'txt': return 'text/plain'
      case 'ustar': return 'application/x-ustar'
      case 'vcd': return 'application/x-cdlink'
      case 'vrml': return 'model/vrml'
      case 'vxml': return 'application/voicexml+xml'
      case 'wav': return 'audio/x-wav'
      case 'wbmp': return 'image/vnd.wap.wbmp'
      case 'wbmxl': return 'application/vnd.wap.wbxml'
      case 'wml': return 'text/vnd.wap.wml'
      case 'wmlc': return 'application/vnd.wap.wmlc'
      case 'wmls': return 'text/vnd.wap.wmlscript'
      case 'wmlsc': return 'application/vnd.wap.wmlscriptc'
      case 'wrl': return 'model/vrml'
      case 'xbm': return 'image/x-xbitmap'
      case 'xht': return 'application/xhtml+xml'
      case 'xhtml': return 'application/xhtml+xml'
      case 'xls': return 'application/vnd.ms-excel'
      case 'xml': return 'application/xml'
      case 'xpm': return 'image/x-xpixmap'
      case 'xsl': return 'application/xml'
      case 'xslt': return 'application/xslt+xml'
      case 'xul': return 'application/vnd.mozilla.xul+xml'
      case 'xwd': return 'image/x-xwindowdump'
      case 'xyz': return 'chemical/x-xyz'
      case 'zip': return 'application/zip'
      default: return 'application/octet-stream'
    }
  }

  getUploadUrl(): string {
    throw new Error('not implemented yet')
  }

  getDownloadUrl(value: string): string {
    throw new Error('not implemented yet')
  }

  getPreviewUrl(value: string): string {
    throw new Error('not implemented yet')
  }

  getThumbnailUrl(value: string): string {
    throw new Error('not implemented yet')
  }

  resolveFileKey(value: string): string {
    if (value.indexOf(FILE_KEY_SEPARATOR) < 0) return value
    const pair = value.split(FILE_KEY_SEPARATOR)
    return pair[0]
  }

  resolveFileName(value: string): string {
    if (value.indexOf(FILE_KEY_SEPARATOR) < 0) return value
    const pair = value.split(FILE_KEY_SEPARATOR)
    return pair.length > 1 ? pair[1] : value
  }

  uploadedFileToValue(file: UploadedFile): string {
    const pairs = [file.key, file.name]
    if (file.specificFileService) pairs.push(file.specificFileService)
    if (file.note) {
      if (pairs.length === 2) pairs.push('') // 补上第三位
      pairs.push(file.note)
    }
    return pairs.join(FILE_KEY_SEPARATOR)
  }

  valueToUploadedFile(value: string): UploadedFile {
    if (!value) return null
    const rawUrl = value.startsWith('http') ? value : null
    const pair = value.split(FILE_KEY_SEPARATOR)
    return {
      key: pair.length > 0 ? pair[0] : value,
      name: pair.length > 1 ? pair[1] : value,
      specificFileService: pair.length > 2 ? pair[2] : undefined,
      note: pair.length > 3 ? pair[3] : undefined,
      url: rawUrl || this.getThumbnailUrl(value),
      previewUrl: rawUrl || this.getPreviewUrl(value),
      downloadUrl: rawUrl || this.getDownloadUrl(value)
    } as UploadedFile
  }

  downloadFile(file: UploadedFile, filename: string) {
    this.downloader.get(file.downloadUrl, {
      responseType: 'arraybuffer'
    }).then(resp => {
      fileDownload(resp.data, filename, resp.headers['content-type'])
    })
  }
}

class FileServiceNgx extends AbstractFileService {
  private readonly host: string
  private readonly uploadAxios: AxiosInstance

  constructor(host: string) {
    super()
    this.host = host
    this.uploadAxios = axios.create({
      baseURL: this.getUploadUrl(),
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data; boundary=Multipart Boundary' }
    })
  }

  getUploadUrl(): string {
    return `${this.host}/upload`
  }

  getDownloadUrl(value: string): string {
    return `${this.host}/get/${this.resolveFileKey(value)}`
  }

  getPreviewUrl(value: string): string {
    return `${this.host}/preview/${this.resolveFileKey(value)}`
  }

  getThumbnailUrl(value: string): string {
    return `${this.host}/thumbnail/${this.resolveFileKey(value)}`
  }

  upload(file: File): Promise<string> {
    const formData = new FormData()
    formData.append('fileUpload', file, file.name)
    return this.uploadAxios.request({
      url: this.getUploadUrl(),
      data: formData
    }).then(resp => resp.data as string)
  }
}

class FileServiceAts extends AbstractFileService {
  private readonly host: string

  constructor(host: string) {
    super()
    this.host = host
  }

  getUploadUrl(): string {
    return `${this.host}/upload`
  }

  getDownloadUrl(value: string): string {
    return `${this.host}/api/attachment/${this.resolveFileKey(value)}/get`
  }

  getPreviewUrl(value: string): string {
    return `${this.host}/api/attachment/${this.resolveFileKey(value)}/preview`
  }

  getThumbnailUrl(value: string): string {
    return `${this.host}/api/attachment/${this.resolveFileKey(value)}/thumbnail`
  }
}

class FileServiceTencentCOS extends AbstractFileService {
  private readonly host: string
  private readonly policy: string
  private readonly qAk: string
  private readonly qKeyTime: string
  private readonly qSignature: string
  private readonly uploadAxios: AxiosInstance

  constructor(host: string, policy: string, qAk: string,
              qKeyTime: string, qSignature: string) {
    super()
    this.host = host
    this.policy = policy
    this.qAk = qAk
    this.qKeyTime = qKeyTime
    this.qSignature = qSignature
    this.uploadAxios = axios.create({
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data; boundary=Multipart Boundary' }
    })
  }

  getUploadUrl(): string {
    return `${this.host}`
  }

  getDownloadUrl(value: string): string {
    return `${this.host}/${this.resolveFileKey(value)}`
  }

  getPreviewUrl(value: string): string {
    if (this.getFileType(value) === FileType.PDF) {
      return `${this.host}/${this.resolveFileKey(value)}?ci-process=doc-preview&page=1&srcType=pdf&ImageParams=imageView2/3/w/1024/h/1024`
    } else if (this.getFileType(value) === FileType.WORD) {
      return `${this.host}/${this.resolveFileKey(value)}?ci-process=doc-preview&page=1&srcType=doc&ImageParams=imageView2/3/w/1024/h/1024`
    } else if (this.getFileType(value) === FileType.EXCEL) {
      return `${this.host}/${this.resolveFileKey(value)}?ci-process=doc-preview&page=1&srcType=xls&ImageParams=imageView2/3/w/1024/h/1024`
    } else if (this.getFileType(value) === FileType.IMAGE) {
      return `${this.host}/${this.resolveFileKey(value)}?imageView2/3/w/1024/h/1024`
    } else {
      return null
    }
  }

  getThumbnailUrl(value: string): string {
    if (this.getFileType(value) === FileType.PDF) {
      // 预览https://www.tencentcloud.com/zh/document/product/1045/47929
      return `${this.host}/${this.resolveFileKey(value)}?ci-process=doc-preview&page=1&srcType=pdf&ImageParams=imageView2/3/w/256/h/256`
    } else if (this.getFileType(value) === FileType.WORD) {
      return `${this.host}/${this.resolveFileKey(value)}?ci-process=doc-preview&page=1&srcType=doc&ImageParams=imageView2/3/w/256/h/256`
    } else if (this.getFileType(value) === FileType.EXCEL) {
      return `${this.host}/${this.resolveFileKey(value)}?ci-process=doc-preview&page=1&srcType=xls&ImageParams=imageView2/3/w/256/h/256`
    } else if (this.getFileType(value) === FileType.IMAGE) {
      // 缩略图参考https://cloud.tencent.com/document/product/436/44893
      return `${this.host}/${this.resolveFileKey(value)}?imageView2/3/w/256/h/256`
    } else {
      return null
    }
  }

  upload(file: File): Promise<string> {
    const formData = new FormData()
    const fileKey = _.has(file, 'key') ? this.resolveFileKey(_.get(file, 'key')) : uuidv4()
    formData.append('key', fileKey)
    formData.append('policy', this.policy)
    // 可以从后台获取临时的签名，后台需要引入腾讯云的工具包，另外桶是配置成读公开的，不需要签名，有被盗刷流量风险
    formData.append('q-ak', this.qAk)
    formData.append('q-key-time', this.qKeyTime)
    formData.append('q-signature', this.qSignature)
    formData.append('q-sign-algorithm', 'sha1')
    formData.append('Content-Type', this.getContentType(file.name))
    formData.append('Content-Disposition', `attachment;filename=${file.name}`)
    formData.append('file', file)
    return this.uploadAxios.request({
      url: this.host,
      data: formData
    }).then(() => fileKey)
  }
}

const services: Indexed = {}
export default {
  getFileService(vendor: string = undefined, fileKey: string = undefined): FileService {
    if (fileKey?.indexOf(FILE_KEY_SEPARATOR) > 0) {
      const pair = fileKey.split(FILE_KEY_SEPARATOR)
      vendor = (pair.length > 2 ? pair[2] : undefined) || vendor
    }
    if (!vendor) vendor = LocalDbDao.getGlobalVariable(GV_FILE_DEFAULT_SVC_VENDOR)
    if (!(vendor in services)) {
      throw new Error(`file service ${vendor} is not available`)
    }
    return services[vendor]
  },

  init() {
    services.ngx = new FileServiceNgx(LocalDbDao.getGlobalVariable(GV_FILE_SVC_NGX_HOST))
    services.ats = new FileServiceAts(LocalDbDao.getGlobalVariable(GV_FILE_SVC_ATS_HOST))
    services['tencent-cos'] = new FileServiceTencentCOS(
      LocalDbDao.getGlobalVariable(GV_FILE_SVC_TENCENT_COS_HOST),
      LocalDbDao.getGlobalVariable(GV_FILE_SVC_TENCENT_COS_POLICY),
      LocalDbDao.getGlobalVariable(GV_FILE_SVC_TENCENT_COS_Q_AK),
      LocalDbDao.getGlobalVariable(GV_FILE_SVC_TENCENT_COS_Q_KEY_TIME),
      LocalDbDao.getGlobalVariable(GV_FILE_SVC_TENCENT_COS_Q_SIGNATURE))
  }
}
