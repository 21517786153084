










import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { showMdoProductOrderDialog } from '@/module/plugin-md-product-order/mdo-util'
import _ from 'lodash'

@Component
export default class CmpMdoProductOrderCodeCellRenderer extends Vue implements ICellRendererComp {
  private params!: ICellRendererParams
  private mProductOrderCodes: string[] = []

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    if (!params.value) return true
    if (!_.isArray(params.value)) {
      this.mProductOrderCodes = [params.value]
    } else {
      this.mProductOrderCodes = params.value
    }
    return true
  }

  onCodeClick(productOrderCode: string) {
    showMdoProductOrderDialog(this, productOrderCode)
  }
}
